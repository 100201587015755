import { FC, useState } from 'react'
import { IDocumentsProps } from './Documents.types'
import { Stack, Typography } from '@mui/material'
import { FieldArray, useFormikContext } from 'formik'
import { theme } from 'styles/theme'
import { PrescriptionFormData } from '../../PrescriptionsForm.types'
import { FileLabel, HiddenInput } from './Documents.styles'
import { Button } from 'components/Button'
import { downloadMedia } from 'utils/downloadMedia'
import { PdfViewerProps } from 'components/PdfViewer'
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Download as DownloadIcon,
  InsertDriveFile as FileIcon,
} from '@mui/icons-material'
import { PdfViewerPopup } from '../PdfViewerPopup'

export const Documents: FC<IDocumentsProps> = ({ viewingOnly }) => {
  const { values, setFieldValue, initialValues } = useFormikContext<PrescriptionFormData>()
  const [openedPdfData, setOpenedPdfData] = useState<PdfViewerProps | null>(null)

  const onAddFile = (e: React.ChangeEvent<HTMLInputElement>, insert: (index: number, value: File) => void) => {
    const file = e.target.files![0]
    if (!file) return
    insert(0, file)
    e.target.value = ''
  }

  const downloadFile = (file?: File | null, link?: string) => {
    if (file) {
      const url = URL.createObjectURL(file)
      const link = document.createElement('a')
      link.href = url
      link.download = file.name
      link.click()
      URL.revokeObjectURL(url)
      link.remove()
    }
    if (link) downloadMedia(link)
  }

  const openViewer = (fileUrl: string, title: string, onDownload: () => void, onDelete: () => void) => {
    const onDeleteWithClose = () => {
      onDelete()
      setOpenedPdfData(null)
    }

    setOpenedPdfData({
      fileUrl,
      title,
      onDownload,
      onDelete: onDeleteWithClose,
    })
  }

  const onViewerClose = () => {
    setOpenedPdfData(null)
  }

  const fileBlock = (name: string, onDownload: () => void, onDelete: () => void, onViewerOpen: () => void) => {
    const splittedName = name.split('.')
    const format = splittedName[splittedName.length - 1]

    return (
      <Stack direction={'row'} justifyContent={'space-between'} width={'100%'}>
        <Stack
          onClick={format === 'pdf' ? onViewerOpen : undefined}
          style={{ cursor: format === 'pdf' ? 'pointer' : 'initial' }}
          direction={'row'}
          alignItems={'center'}
          spacing={0.5}
        >
          <FileIcon fontSize={'medium'} color={'primary'} />
          <Typography variant={'body2'} color={theme.palette.primary.main} lineHeight={'inherit'}>
            {name}
          </Typography>
        </Stack>
        <Stack direction={'row'} spacing={1}>
          <DownloadIcon
            onClick={onDownload}
            style={{ fill: theme.palette.primary.main, cursor: 'pointer', marginTop: '1px' }}
          />
          {!viewingOnly && (
            <DeleteIcon onClick={onDelete} style={{ fill: theme.palette.error.main, cursor: 'pointer' }} />
          )}
        </Stack>
      </Stack>
    )
  }

  return (
    <Stack>
      <FieldArray
        name='filesForCreate'
        render={({ insert, remove }) => (
          <>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography variant='subtitle1' textAlign={'start'} color={theme.palette.text.dark}>
                Документы
              </Typography>
              {!viewingOnly && (
                <>
                  <FileLabel isDisabled={initialValues.status !== 'CREATED'} htmlFor={`uploadFile`}>
                    <Button
                      disabled={initialValues.status !== 'CREATED'}
                      icon={true}
                      color='secondary'
                      variant='outlined'
                      style={{ pointerEvents: 'none' }}
                    >
                      <AddIcon />
                    </Button>
                  </FileLabel>
                  <HiddenInput
                    onChange={(e) => onAddFile(e, insert)}
                    type='file'
                    id={`uploadFile`}
                    className='none'
                    accept='.docx, .pdf'
                  />
                </>
              )}
            </Stack>
            <Stack spacing={1.5} marginTop={3} alignItems={'flex-start'}>
              {values.filesForCreate.length || values.files.length ? (
                <>
                  {values.filesForCreate.map((item, index) => {
                    // {values.filesForCreate.reverse().map((item, index) => (
                    const url = URL.createObjectURL(item)

                    const onDownload = () => downloadFile(item)
                    const onDelete = () => remove(index)
                    const onDrawerOpen = () => {
                      openViewer(url, item.name, onDownload, onDelete)
                    }

                    return fileBlock(item.name, onDownload, onDelete, onDrawerOpen)
                  })}
                  {values.files.length ? (
                    <FieldArray
                      name='files'
                      render={({ insert, remove }) => (
                        <>
                          {values.files.map((item, index) => {
                            const name = item.link.split('/').slice(-1)[0]
                            const onDownload = () => downloadFile(null, item.link)
                            const onDelete = () => {
                              setFieldValue('filesIdsToDelete', [...values.filesIdsToDelete, item.id])
                              remove(index)
                            }
                            const onDrawerOpen = () => {
                              openViewer(item.link, name, onDownload, onDelete)
                            }

                            return fileBlock(name, onDownload, onDelete, onDrawerOpen)
                          })}
                        </>
                      )}
                    />
                  ) : null}
                </>
              ) : (
                '—'
              )}
            </Stack>
          </>
        )}
      />

      <PdfViewerPopup opened={openedPdfData !== null} viewerProps={openedPdfData} onViewerClose={onViewerClose} />
    </Stack>
  )
}
