export type SortableColumn =
  | 'number'
  | 'status'
  | 'period_dateStart'
  | 'contractor'
  | 'contractorStatus'
  | 'period_dateEnd'
  | 'period_dateDone'
  | 'prescription'
  | 'type'

export const sortableColumns: SortableColumn[] = [
  'number',
  'status',
  'period_dateStart',
  'contractor',
  'contractorStatus',
  'period_dateEnd',
  'period_dateDone',
  'prescription',
  'type',
]

export type RemarkFilterableColumn =
  | 'number'
  | 'status'
  | 'period_dateStart'
  | 'contractor'
  | 'contractorStatus'
  | 'period_dateEnd'
  | 'period_dateDone'
  | 'prescription'
  | 'type'

export const filterableColumns: RemarkFilterableColumn[] = [
  'number',
  'status',
  'contractor',
  'contractorStatus',
  'period_dateStart',
  'period_dateEnd',
  'period_dateDone',
  'prescription',
  'type',
]

export const rangingDates: RemarkFilterableColumn[] = ['period_dateStart', 'period_dateEnd', 'period_dateDone']
